import Logo from 'design/assets/images/gateforce_logo.svg'
import FairmoneyLogo from 'design/assets/images/fairmoney_logo.svg'
// import ShopBase from 'design/icons/shop-base.svg'
import { capitalize } from 'lib/formatters'
import styled from 'styled-components'

import Box from 'design/elements/Box'
import Spacer from 'design/elements/Spacer'
import { ShowTree } from 'lib/gate/GateComponents'
import { AllRoles, NOTIFICATION_VIEW, except, roles } from 'lib/models'
import { NavLink } from 'react-router-dom'
import { useAuthState } from 'auth/context/Auth.Context'

import FmDashboardBase from 'design/icons/fm-dashboard-base.svg'
import FmAgentsBase from 'design/icons/fm-agents-base.svg'
import FmApiUsersMgmtBase from 'design/icons/fm-api-users-mgmt.svg'
import FmUsersBase from 'design/icons/fm-users-base.svg'
import FmKycBase from 'design/icons/fm-kyc-base.svg'
import FmAccountsBase from 'design/icons/fm-accounts-base.svg'
import FmVirtualAccountsBase from 'design/icons/fm-virtual-accounts-base.svg'
import FmAccountsUtilityBase from 'design/icons/fm-accounts-utility-base.svg'
import FmTransactionsBase from 'design/icons/fm-transactions-base.svg'
import FmNqrRegistrationBase from 'design/icons/fm-nqr-registration-base.svg'
import FmTerminalsBase from 'design/icons/fm-terminals-base.svg'
import FmDispenseErrorsBase from 'design/icons/fm-dispense-errors-base.svg'
import FmChargesBase from 'design/icons/fm-charges-base.svg'
import FmBillersBase from 'design/icons/fm-billers-base.svg'
import FmLoansBase from 'design/icons/fm-loans-base.svg'
import FmLiensBase from 'design/icons/fm-liens-base.svg'
import FmReportsBase from 'design/icons/fm-reports-base.svg'
import FmTmsUtilityBase from 'design/icons/fm-tms-utility-base.svg'
import FmMarketingBase from 'design/icons/fm-marketing-base.svg'
import FmCampaignsBase from 'design/icons/fm-campaigns-base.svg'
import FmSalesBase from 'design/icons/fm-sales-base.svg'
import FmReviewBase from 'design/icons/fm-review-base.svg'
import FmNotificationsBase from 'design/icons/fm-notifications-base.svg'
import FmSettingsBase from 'design/icons/fm-settings-base.svg'
import FmLogoutBase from 'design/icons/fm-logout-base.svg'
import FmLogoutOpen from 'design/icons/fm-logout-open.svg'
import CloseSideMenu from 'design/icons/close-sidemenu-icon.svg'
import Text from 'design/elements/Text'
import { logout } from 'lib/client'
import Button from 'design/elements/Button'

const SideBarStyles = styled.aside`
  color: #bfe1ff;
  background-color: #374674;
  transition: width 0.256s;
  display: flex;
  flex-direction: column;

  .menu {
    flex: 1 0 80%;
  }

  .ham-wrapper {
    padding: 0 1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: all 0.256s linear;
    cursor: pointer;
  }
  .closemenu {
    display: none;
  }

  .logo {
    width: 0;
    height: 1rem;
    overflow: hidden;
    height: inherit;
    float: left;
    transition: 0.256s width;
  }
  .logo2 {
    width: 100%;
    height: 0;
  }
  .collapsed {
    padding: 1rem 1.2rem 1rem 1rem;
    cursor: pointer;
    width: 100%;
  }
  &.isOpen {
    .ham-wrapper {
      padding: 2rem 1rem 1rem 0;
      transition: all 0.256s;
    }
    .logo {
      width: 80%;
    }
    .logo2 {
      width: 0;
    }
    .collapsed {
      display: none;
    }
    .closemenu {
      position: absolute;
      margin-left: 13.75rem;
      display: block;
      width: 2%;
    }
  }
`

const SideBar = ({ toggleSideBar, isOpen }) => {
  const { user } = useAuthState()
  return (
    <SideBarStyles className={isOpen ? 'isOpen' : ''}>
      <div className="ham-wrapper" onClick={toggleSideBar}>
        <img alt="logo" className="logo" src={Logo} />
        <img alt="logo" className="closemenu" src={CloseSideMenu} />
      </div>
      <img alt="logo2" onClick={toggleSideBar || ''} className="collapsed" src={FairmoneyLogo} />

      <Box className="menu">
        <SidebarMenuItem
          title="Dashboard"
          isOpen={isOpen}
          baseIcon={FmDashboardBase}
          text="Dashboard"
          url="/dashboard"
          roles={except([roles?.KYC_TEAM, roles?.LOAN_TEAM])}
        />

        <SectionTitle isOpen={isOpen} text={'User management'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="agents"
          isOpen={isOpen}
          baseIcon={FmAgentsBase}
          text="Agents"
          url="/agents"
          roles={except([roles?.LOAN_TEAM])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="apiUsersManagement"
          isOpen={isOpen}
          baseIcon={FmApiUsersMgmtBase}
          text="API Users Mgt."
          url="/api-user-management"
          roles={except([roles?.KYC_TEAM, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="users"
          isOpen={isOpen}
          baseIcon={FmUsersBase}
          text="Users"
          url="/users"
          roles={[roles?.SUPER_ADMIN, roles?.OPPERATION_HEAD, roles?.ADMIN]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="KYC"
          isOpen={isOpen}
          baseIcon={FmKycBase}
          text="KYC"
          url="/kyc"
          roles={[
            roles?.KYC_TEAM,
            roles?.ADMIN,
            roles?.SUPER_ADMIN,
            roles?.OPPERATION_HEAD,
            roles?.REGIONAL_RETENTION_MANAGER,
          ]}
        />

        <SectionTitle isOpen={isOpen} text={'Account management'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="Accounts"
          isOpen={isOpen}
          baseIcon={FmAccountsBase}
          text="Accounts"
          url="/accounts"
          roles={[
            roles?.SUPER_ADMIN,
            roles?.ADMIN,
            roles?.OPERATIONS_ASSISTANT,
            roles?.OPPERATION_HEAD,
            roles?.ACCOUNTS_TEAM,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Virtual Account"
          isOpen={isOpen}
          baseIcon={FmVirtualAccountsBase}
          text="Virtual Account"
          url="/virtual-accounts"
          roles={except([roles?.KYC_TEAM, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Account Utility"
          isOpen={isOpen}
          baseIcon={FmAccountsUtilityBase}
          text="Account Utility"
          url="/users/fieldstaff-accounts"
          roles={[
            roles?.ADMIN,
            roles?.SUPER_ADMIN,
            roles?.OPERATIONS_MANAGER,
            roles?.OPERATIONS_ASSISTANT,
            roles?.OPPERATION_HEAD,
          ]}
        />

        <SectionTitle isOpen={isOpen} text={'Payments and Transactions'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="transactions"
          isOpen={isOpen}
          baseIcon={FmTransactionsBase}
          text="Transactions"
          url="/transactions"
          roles={except([roles.COMMS, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="NQR Registration"
          isOpen={isOpen}
          baseIcon={FmNqrRegistrationBase}
          text="NQR Registration"
          url="/nqr"
          roles={except([roles.COMMS, roles.KYC_TEAM, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="terminals"
          isOpen={isOpen}
          baseIcon={FmTerminalsBase}
          text="Terminals"
          url="/terminals"
          roles={except([roles.FIELD_SUPERVISOR, roles.COMMS, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="dispense-errors"
          isOpen={isOpen}
          baseIcon={FmDispenseErrorsBase}
          text="Dispense Errors"
          url="/dispense-errors"
          roles={except([roles.POS_TEAM, roles.COMMS, roles.KYC_TEAM, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="charges"
          isOpen={isOpen}
          baseIcon={FmChargesBase}
          text="Charges"
          url="/charges"
          roles={[
            roles?.ADMIN,
            roles?.SUPER_ADMIN,
            roles?.OPERATIONS_MANAGER,
            roles?.OPERATIONS_ASSISTANT,
            roles?.OPPERATION_HEAD,
          ]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="billers"
          isOpen={isOpen}
          baseIcon={FmBillersBase}
          text="Billers"
          url="/billers"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.OPPERATION_HEAD,
          ]}
        />

        <SectionTitle isOpen={isOpen} text={'Lending and Credit'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="Loans V3"
          isOpen={isOpen}
          baseIcon={FmLoansBase}
          text="Loans V3"
          url="/loans-v3"
          roles={[
            roles?.SUPER_ADMIN,
            roles?.ADMIN,
            roles?.OPERATIONS_MANAGER,
            roles?.OPPERATION_HEAD,
            roles?.SUPPORT,
            roles?.REGIONAL_RETENTION_MANAGER,
            roles?.OPERATIONS_ASSISTANT,
            roles?.LOAN_TEAM,
          ]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="lienv1"
          isOpen={isOpen}
          baseIcon={FmLiensBase}
          text="Lien V1"
          url="/liens-v1"
          roles={except([roles?.LOAN_TEAM])}
        />

        <SectionTitle isOpen={isOpen} text={'Reporting and utility'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="reports"
          isOpen={isOpen}
          baseIcon={FmReportsBase}
          text="Reports"
          url="/reports"
          roles={except([roles.COMMS, roles, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="TMS V2"
          isOpen={isOpen}
          baseIcon={FmTmsUtilityBase}
          text="TMS V2"
          url="/tms-v2"
          roles={[
            roles?.SUPER_ADMIN,
            roles?.ADMIN,
            roles?.OPERATIONS_MANAGER,
            roles?.OPERATIONS_ASSISTANT,
            roles?.ACCOUNTS_TEAM,
            roles?.POS_TEAM,
            roles?.SUPPORT,
            roles?.AGGREGATOR_MANAGER,
            roles?.GROWTH_MANAGER,
            roles?.OPPERATION_HEAD,
            roles?.REGIONAL_RETENTION_MANAGER,
          ]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="TMS Utility"
          isOpen={isOpen}
          baseIcon={FmTmsUtilityBase}
          text="TMS Utility"
          url="/tms"
          roles={[
            roles?.SUPER_ADMIN,
            roles?.ADMIN,
            roles?.OPERATIONS_MANAGER,
            roles?.OPERATIONS_ASSISTANT,
            roles?.ACCOUNTS_TEAM,
            roles?.POS_TEAM,
            roles?.SUPPORT,
            roles?.AGGREGATOR_MANAGER,
            roles?.OPPERATION_HEAD,
            roles?.REGIONAL_RETENTION_MANAGER,
          ]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Miscellaneous"
          isOpen={isOpen}
          baseIcon={FmDispenseErrorsBase}
          text="Miscellaneous"
          url="/miscellaneous"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.POS_TEAM,
            roles.SUPPORT,
            roles.AGGREGATOR_MANAGER,
            roles.GROWTH_MANAGER,
            roles.OPPERATION_HEAD,
            roles.REGIONAL_RETENTION_MANAGER,
          ]}
        />

        <SectionTitle isOpen={isOpen} text={'Marketing & Campaigns'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="Marketing"
          isOpen={isOpen}
          baseIcon={FmMarketingBase}
          text="Marketing"
          url="/marketing"
          roles={[roles?.SUPER_ADMIN, roles?.ADMIN, roles?.OPERATIONS_MANAGER, roles?.COMMS]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="campaigns"
          isOpen={isOpen}
          baseIcon={FmCampaignsBase}
          text="Campaigns"
          url="/campaigns"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.OPPERATION_HEAD,
          ]}
        />

        <SectionTitle isOpen={isOpen} text={'Sales & Review'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="Sales"
          isOpen={isOpen}
          baseIcon={FmSalesBase}
          text="Sales"
          url="/sales"
          roles={except([roles?.KYC_TEAM, roles?.LOAN_TEAM])}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Review"
          isOpen={isOpen}
          baseIcon={FmReviewBase}
          text="Review"
          url="/review"
          roles={[roles?.SUPER_ADMIN, roles?.ADMIN, roles?.SUPPORT]}
        />

        <SectionTitle isOpen={isOpen} text={'Notifications & Settings'} />

        <Spacer mt="lg" />
        <SidebarMenuItem
          title="notification"
          isOpen={isOpen}
          baseIcon={FmNotificationsBase}
          text="Notification"
          url="/notification"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.COMMS,
            roles.OPPERATION_HEAD,
            roles.SUPPORT,
            NOTIFICATION_VIEW.includes(user?.username.toLowerCase()) && roles.REGIONAL_RETENTION_MANAGER,
          ]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="settings"
          isOpen={isOpen}
          baseIcon={FmSettingsBase}
          text="Settings"
          url="/settings"
          roles={AllRoles}
        />

        <Spacer mt="lg" />
        <Box onClick={logout} mx={isOpen ? '0.063rem' : '0.375rem'}>
          <Button
            style={{
              borderRadius: '0.438rem',
              padding: '1.6rem 1rem',
              color: 'white',
              backgroundColor: isOpen ? '#374674' : '#F03E62',
              borderColor: '#374674',
              display: 'flex',
            }}
          >
            <img src={isOpen ? FmLogoutOpen : FmLogoutBase} alt="log out" />
            {isOpen ? <Spacer mr="md" /> : null}
            {isOpen ? 'Log Out' : ''}
          </Button>
        </Box>

        {/* <Spacer mt="xl" />
        <SidebarMenuItem
          title="Payforce Shop"
          isOpen={isOpen}
          baseIcon={ShopBase}
          text="Payforce Shop"
          url="/payforce-shop"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.ACCOUNTS_TEAM,
            roles.POS_TEAM,
            roles.SUPPORT,
            roles.AGGREGATOR_MANAGER,
            roles.GROWTH_MANAGER,
            roles.OPPERATION_HEAD,
            roles.REGIONAL_RETENTION_MANAGER,
          ]}
        /> */}

        {/* <Spacer mt="xl" /> */}
        {/* <SidebarMenuItem
          title="Loans"
          isOpen={isOpen}
          baseIcon={LoanLight}
          text="Loans"
          url="/loans"
          roles={[
            roles?.SUPER_ADMIN,
            roles?.ADMIN,
            roles?.OPERATIONS_MANAGER,
            roles?.OPPERATION_HEAD,
            roles?.SUPPORT,
            roles?.REGIONAL_RETENTION_MANAGER,
            roles?.OPERATIONS_ASSISTANT,
          ]}
        /> */}
        {/* <SidebarMenuItem
          title="Loans V2"
          isOpen={isOpen}
          baseIcon={WalletLoanV2Light}
          text="Loans V2"
          url="/loans-v2"
          roles={[
            roles?.SUPER_ADMIN,
            roles?.ADMIN,
            roles?.OPERATIONS_MANAGER,
            roles?.OPPERATION_HEAD,
            roles?.SUPPORT,
            roles?.REGIONAL_RETENTION_MANAGER,
            roles?.OPERATIONS_ASSISTANT,
          ]}
        /> */}

        {/* <Spacer mt="xl" />
        <SidebarMenuItem
          title="lien"
          isOpen={isOpen}
          baseIcon={LienBase}
          text="Liens"
          url="/lien"
          roles={[...HIGH_ROLES, roles?.SUPPORT]}
        /> */}
      </Box>
    </SideBarStyles>
  )
}

const MenuItem = styled.div`
  cursor: pointer;
  width: 65%;
  & a {
    position: relative;
    padding: 0 1rem;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;
    left: 10%;
  }

  span.name {
    color: white;
    line-height: 1.38;
    display: inline-block;
    margin-left: 1rem;
    transform: translate3d(-0.313rem, 0, 0);
    max-height: 1.6rem;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }
  &.isOpen {
    width: 95%;
    a {
      left: 0%;
    }
    span.name {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      height: auto;
    }
    [data-active-icon],
    [data-active-icon] {
      left: 7.2%;
    }
  }
  [data-active-icon] {
    opacity: 0;
    transform: scale(0);
  }
  [data-active-icon],
  [data-active-icon] {
    transition: all 0.1s linear;
    position: absolute;
    left: 20%;
  }
  & .active {
    width: 95%;
    margin-left: 0.5rem;
    border-radius: 0.25rem;
    padding: 0.625rem 0.313rem;
    background-color: #252f4d;
    span.content {
      margin-left: 1.7rem;
    }

    [data-base-icon] {
      opacity: 0;
      transform: scale(0);
    }
    [data-active-icon] {
      opacity: 1;
      transform: scale(1);
    }
  }
`
const SidebarMenuItem = ({ isOpen, baseIcon, title, url, text, roles }) => (
  <ShowTree forRoles={roles}>
    <MenuItem className={isOpen ? 'isOpen' : 'isClose'}>
      <NavLink title={title} to={url}>
        <span className="image">
          <img alt={title} src={baseIcon} data-base-icon />
          <img alt={`${title}-on`} src={baseIcon} data-active-icon />
        </span>
        {isOpen && <span className="name content">{text.split(' ').map(capitalize).join(' ')}</span>}
      </NavLink>
    </MenuItem>
  </ShowTree>
)

const SectionTitle = ({ text, isOpen }) => (
  <Box>
    <Text color="#B7C6F4" fontSize="0.75rem" mt="xl" ml="md">
      {isOpen ? text : ''}
    </Text>
  </Box>
)

export default SideBar
