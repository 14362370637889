import client from 'lib/client'
import axios from 'axios'
import { prodAlternateClient, handleResponse } from 'virtualAccounts/Virtual.accounts.api.client'
import { baseUrl } from 'lib/config'

export const login = (body) =>
  client(`au/account/login`, body, {}, false, `${process.env.REACT_APP_ALT_STAGING_URL}${baseUrl}`)
export const requestPasswordReset = (body) => client('au/account/reset-password', body)

export const validateOTP = (body) => client('i/mobile/agent/validate-otp', body)
export const changePassword = (body) => client(`au/account/change-password`, body, { method: 'PATCH' })

export const confirmReset = (body) => client(`i/mobile/agent/confirm-reset`, body)
export const getLocation = () => axios.get(`https://api64.ipify.org/?format=json`)

// +++++++++++++++++ FUND TRANSFER +++++++++++++++++++++++++++++++++++
export const getAccountOptions = () => handleResponse(prodAlternateClient.get(`b/bank/GetFundTransferList`))
export const TransferFunds = (body) => client(`t/transaction/FundTransfer`, body)
